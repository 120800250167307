import React from 'react'
import Layout from "../components/layout";
import Timer from "../components/timer"
import Particles from 'react-particles-js';

const particlesOptions = {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 800
      }
    }
  }
};

class IndexPage extends React.Component {
  state={
    email: ''
  }
  handleV = (e) => {
    this.setState({ email: e.target.value })
  }
  render() {
    return(
      <Layout>
        <div className="bgimg">
          <div className="bg-layout">
          <Particles className="particles" params={particlesOptions} />
          <div className="middle glass">
              <div className="content">
                <img className="brand-logo" src={require('../images/logo.svg')} alt=""/>
                <h1 className="h1">COMING SOON!</h1>
                <Timer date="03/03/2021" time="19:00"/>
                <div id="mc_embed_signup">
              </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
